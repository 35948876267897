import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { 
  pubsubTopicSchemaContentRouteRef,
  pubsubEntityContentRouteRef
} from './routes'

export const pubsubPlugin = createPlugin({
  id: 'pubsub',
});

export const PubsubTopicSchemaContent = pubsubPlugin.provide(
  createRoutableExtension({
    name: 'PubsubSchemaPage',
    component: () =>
      import('./components/PubsubTopicSchemaContent').then(m => m.PubsubTopicSchemaContent),
    mountPoint: pubsubTopicSchemaContentRouteRef,
  })
)

export const PubSubPage = pubsubPlugin.provide(
  createRoutableExtension({
    name: 'EntityPubSubContent',
    component: () => 
      import('./components/EntityPagePubSub').then(m => m.EntityPagePubSub),
    mountPoint: pubsubEntityContentRouteRef
  })
)