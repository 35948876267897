import { ResourceEntity, Entity } from '@backstage/catalog-model';

function strCmp(a: string | undefined, b: string | undefined): boolean {
  return Boolean(
    a && a?.toLocaleLowerCase('en-US') === b?.toLocaleLowerCase('en-US'),
  );
}

function strCmpAll(value: string | undefined, cmpValues: string | string[]) {
  return typeof cmpValues === 'string'
    ? strCmp(value, cmpValues)
    : cmpValues.some(cmpVal => strCmp(value, cmpVal));
}

export function isResourceType (types: string | string[]) {
  return (entity: Entity) => {
    if(!strCmp(entity.kind, 'resource')) {
      return false;
    }
    const resourceEntity = entity as ResourceEntity;
    return strCmpAll(resourceEntity.spec.type, types)
  }
}
